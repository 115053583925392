import { Dispatch } from 'redux';
import { IReduxAction } from '../../types';
import logger from '../../libs/utils/logger';
import { IOrderItemRecord, IUpdateOrderInput } from 'types/order.types';
import { getOrderById, updateOrder } from 'services/order.service';

export const GET_ORDER_BY_ID_STARTED = 'GET_ORDER_BY_ID_STARTED';
export const GET_ORDER_BY_ID_FINISHED = 'GET_ORDER_BY_ID_FINISHED';
export const UPDATE_ORDER_STARTED = 'UPDATE_ORDER_STARTED';
export const UPDATE_ORDER_FINISHED = 'UPDATE_ORDER_FINISHED';

export interface OrderState {
  isFetching: boolean;
  order: IOrderItemRecord | null
}

interface UpdateOrderAction {
  type: typeof UPDATE_ORDER_STARTED | typeof UPDATE_ORDER_FINISHED,
  payload: IUpdateOrderInput
}

interface GetOrderByIdAction {
  type: typeof GET_ORDER_BY_ID_STARTED | typeof GET_ORDER_BY_ID_FINISHED,
  payload: IOrderItemRecord // payload
}

export type OrderAction = UpdateOrderAction | GetOrderByIdAction;

export const getOrderByIdStarted = () => ({
  type: GET_ORDER_BY_ID_STARTED,
});

export const getOrderByIdFinished = (data: OrderState): IReduxAction => ({
  type: GET_ORDER_BY_ID_FINISHED,
  payload: data,
});

export const getOrderByIdAction = (id: number) => {
  // The typings for redux + redux-thunk are weak and incorrect in some cases
  return (dispatch: Dispatch<any>) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    dispatch(getOrderByIdStarted());

    return getOrderById({id}).then(data => {
      dispatch(getOrderByIdFinished(data));
    }).catch((err) => {
      logger.error(err);
    })
  };
};

export const updateOrderStarted = () => ({
  type: UPDATE_ORDER_STARTED,
});

export const updateOrderFinished = (data: any): IReduxAction => ({
  type: UPDATE_ORDER_FINISHED,
  payload: data
});

export const updateOrderAction = (data: IUpdateOrderInput) => {
  // The typings for redux + redux-thunk are weak and incorrect in some cases
  return (dispatch: Dispatch<any>) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    dispatch(updateOrderStarted());

    return updateOrder(data).then(data => {
      dispatch(updateOrderFinished(data));
    }).catch((err) => {
      logger.error(err);
    })
  };
};
