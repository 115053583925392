import React from 'react';

interface IPageLoaderType {
    className?: string
}
// See more loading icons here:
// https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons
const PageLoader : React.FC<IPageLoaderType> = ({className}) => (
    <div className={className || `page-loader`}>
        <em className="fas fa-circle-notch fa-spin fa-2x text-muted"></em>
    </div>
)

export default PageLoader;