import {
  GET_ORDERS_STARTED,
  GET_ORDERS_FINISHED,
  GET_ORDERS_BY_MERCHANT_ID_STARTED,
  GET_ORDERS_BY_MERCHANT_ID_FINISHED,
  OrdersAction,
  OrdersState,
} from '../actions/orders.actions';

const initialState: OrdersState = {
  isFetching: false,
  orders: [],
};

const ordersReducer = (state = initialState, action: OrdersAction): OrdersState => {
  let newState;

  switch (action.type) {
    case GET_ORDERS_STARTED:
    case GET_ORDERS_BY_MERCHANT_ID_STARTED:
      return {
        ...initialState,
        isFetching: true,
      } as OrdersState;

    case GET_ORDERS_FINISHED:
    case GET_ORDERS_BY_MERCHANT_ID_FINISHED:
      newState = {
        ...state,
        isFetching: false,
        orders: action.payload,
      };

      return newState as OrdersState;

    default:
      return state;
  }
};

export default ordersReducer;
