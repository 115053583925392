const scheduledExportMutations: object = {
  CREATE_SCHEDULED_EXPORT: (params: object) => {
    const query = `
      mutation CreateScheduledExport($data: CreateScheduledExportInput!) {
         scheduledExports{
            create(scheduledExport: $data){
              id
              name
              timeOfDay
              store {
                storeCode
              }
              merchant {
                id
              }
              statusId
              exportLocationId
              startDate
              endDate
            }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: {data: params}
    };
  },

  EDIT_SCHEDULED_EXPORT: (params: object) => {
    const query = `
      mutation EditScheduledExport($data: UpdateScheduledExportInput) {
        scheduledExports{
          update(scheduledExport: $data){
            id
            name
            timeOfDay
            store {
              storeCode
            }
            merchant {
              id
            }
            statusId
            exportLocationId
            startDate
            endDate
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: {data: params}
    };
  },

  DELETE_SCHEDULED_EXPORT: (params: object) => {
    const query = `
      mutation DeleteScheduledExport($data: DeleteScheduledExportInputDto) {
        scheduledExports{
          delete(scheduledExport: $data){
            status
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: {data: params}
    };
  },

};

export default scheduledExportMutations as {
  [key: string]: Function;
};
