import graphqlFetch from '../graphql';

export const getOrderMetrics = () => {
  return graphqlFetch('GET_ORDER_METRICS')
    .then((res: any) => {
      return res.response.data.orderMetrics || [];
    })
    .catch((err: any) => {
      throw err
    })
}
