const storeQueries: object = {
  GET_STORES_BY_MERCHANT_ID: (params: object) => {
    const query = `query GetStores($merchantId: Int!){
      merchant(id: $merchantId) {
        stores {
          id
          storeName
          storeCode
          storeUrl
          isActive
          dateCreated
          dateModified
        }
      }
    }`;

    return {
      operationName: 'GetStores',
      query,
      variables: params
    }
  },

  GET_ALL_STORES: (params: object) => {
    const query = `query GetAllStores {
      stores {
        id
        storeName
        storeCode
        storeUrl
        isActive
        dateCreated
        dateModified
      }
    }`;

    return {
      operationName: 'GetAllStores',
      query,
      variables: params
    }
  }
}

export default storeQueries as {
  [key: string]: Function;
}
