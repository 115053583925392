/**
 * check if a literal is falsy or not
 */
import { IFirebaseTokenClaimsType, IFirebaseUserType } from '../../types/user.types';

const isLiteralFalsey = (variable: unknown) => {
	return variable === '' || variable === false || variable === 0;
};

/**
 * check if a variable contains a reference type (not a literal)
 */

const isPrimitive = (arg: unknown) => {
	return typeof arg === 'object' || (typeof arg !== 'undefined' && arg !== null && typeof arg === 'function');
};

/**
 * check the type name of any primitive and/or reference variable
 */
const checkTypeName = (target: unknown, type: string) => {
	let typeName = '';
	let match = false;

	if (isLiteralFalsey(target) || !isPrimitive(target)) {
		typeName = typeof target;
	} else {
		typeName = Object.prototype.toString.call(target).replace(/^\[object (.+)\]$/, '$1');
	}

	match = Boolean(typeName.toLowerCase().indexOf(type) + 1);

	return match;
};

/**
 * get the actual type of any variable
 *
 * @param {mixed} value
 * @param {String | Array | Function} type
 */
export const strictTypeOf = (value: unknown, type: string | Function | string[]) => {
	let result = false;
	/* eslint-disable-next-line no-param-reassign */
	type = type || [];

	if (typeof type === 'object') {
		if (typeof type.length !== 'number') {
			return result;
		}

		let bitPiece = 0;
		/* eslint-disable-next-line no-param-reassign */
		type = [].slice.call(type) as string[];

		type.forEach(($type) => {
			let localResult = false;
			if (typeof $type === 'function') {
				localResult = value instanceof $type;
			}
			/* eslint-disable-next-line no-bitwise */
			bitPiece |= Number(localResult || checkTypeName(value, $type));
		});
		result = Boolean(bitPiece);
	} else {
		if (typeof type === 'function') {
			result = value instanceof type;
		}
		result = result || checkTypeName(value, type as string);
	}
	return result;
};

/**
 * A function that does nothing: returns the first argument only
 *
 * @param {mixed} arg
 * @param {mixed} context
 */
export const noop = (arg: unknown, context?: unknown) => {
	return arg || context;
};

/**
 * A function to group JSX class names for react elements
 *
 * @param {*} styles
 */

export const composeClasses = (...styles: (string | boolean | undefined)[]): string => styles.filter((item) => item).join(' ');

/**
 * A function that returns date in the DD/MM/YYYY format
 *
 * @param {*} styles
 */
export const formatDate = (dateString: string) => {
  const dateInstance = new Date(dateString)
  return dateInstance.toLocaleDateString();
}

/**
 * A function that returns date and time in the DD/MM/YYYY hhmmss format
 *
 * @param {*} styles
 */
export const formatDateAndTime = (dateString: string) => {
  const dateTimeInstance = new Date(dateString);
  return dateTimeInstance.toLocaleString();
}


export const emailRegex = (value: string) => /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|io|store|org|co\.uk|co|ca|co\.za|net|ng|com\.ng|gov|mil|biz|info|mobi|name|edu|nti|aero|jobs|museum)\b$/.test(value || '');

export const extractFirebaseAuthInfo = (firebaseUser: IFirebaseTokenClaimsType): IFirebaseUserType => {
  return {
    localId: firebaseUser.user_id,
    emailAddress: firebaseUser.email,
    emailVerified: firebaseUser.email_verified,
  }
}
