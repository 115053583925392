const exportLocationMutations: object = {
  CREATE_EXPORT_LOCATION: (params: object) => {
    const query = `
      mutation CreateExportLocation($data: CreateExportLocationInput!) {
         exportLocations{
            create(exportLocation: $data){
              id
              name
              location
              type
            }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: {data: params}
    };
  },

  EDIT_EXPORT_LOCATION: (params: object) => {
    const query = `
      mutation EditExportLocation($data: UpdateExportLocationInput) {
        exportLocations{
          update(exportLocation: $data){
            id
            name
            location
            type
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: {data: params}
    };
  },

  DELETE_EXPORT_LOCATION: (params: object) => {
    const query = `
      mutation DeleteExportLocation($data: DeleteExportLocationInputDto) {
        exportLocations{
          delete(exportLocation: $data){
            status
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: {data: params}
    };
  },

};

export default exportLocationMutations as {
  [key: string]: Function;
};
