import StorageService from './storage.service';
import { TOKEN_EXPIRE_TIME_KEYNAME } from '../constants';
import { firebaseAuth  } from './firebase.service';

const isAuthenticated = () => {
  const access_token = StorageService.getToken();
  return access_token && isTokenValid();
};

const logout = () => {
  firebaseAuth.signOut()
    .then(() => {
      StorageService.clear();
      window.location.href = '/login';
    })
    .catch((err) => {
      throw err;
    });
}

const isTokenValid = () => {
  // check token validation, if auth fails return false
  let expires_in = parseInt(localStorage.getItem(TOKEN_EXPIRE_TIME_KEYNAME)!);

  if(!expires_in){
    return false;
  }
  try{
    if(Date.now() >= expires_in * 1000){
      // logout();
      logout();
    }
  } catch(e) {
    return false;
  }
  return true;
}

export default {
  isAuthenticated,
  logout
}
