const merchantMutations: object = {
  CREATE_MERCHANT: (params: object) => {
    const query = `
      mutation CreateMerchant($data: CreateMerchantInput) {
        merchants {
          create(merchant: $data) {
            id,
            name
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  },
  EDIT_MERCHANT: (params: object) => {
    const query = `
      mutation EditMerchant($data: UpdateMerchantInput) {
        merchants {
          update(merchant: $data) {
            id
            name
            dateCreated
            dateModified
            stores {
              id
              storeName
              storeCode
              storeUrl
              isActive
            }
            contacts {
              id
              firstName
              lastName
              emailAddress
              mobileNumber
              countryId
              regionId
              addressLine1
              addressLine2
              addressLine3
            }
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  }
};

export default merchantMutations as {
  [key: string]: Function;
};
