const userMutations: object = {
  CREATE_USER: (params: object) => {
    const query= `
      mutation CreateUser($data: CreateUserInput!) {
        users {
          create(user: $data) {
            id,
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  },

  EDIT_USER: (params: object) => {
    const query= `
      mutation EditUser($data: UpdateUserInput) {
        users {
          update(user: $data) {
            id,
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  },

  DELETE_USER: (params: object) => {
    const query= `
      mutation DeleteUser($data: DeleteUserInputDto) {
        users {
          delete(user: $data) {
            status,
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  },

  CREATE_USER_GROUP_MAP: (params: object) => {
    const query = `
      mutation CreateUserGroupMap($data: CreateUserGroupMapInput) {
        userGroups {
          createUserGroupMap(userGroupMap: $data) {
            id
          }
        }
     }`;

    return {
      operationName: null,
      query,
      variables: { data: params }
    };
  },
};

export default userMutations as {
  [key: string]: Function;
};
