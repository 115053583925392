import React from 'react';
import { Omit } from 'react-router';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import AuthService from 'services/authentication.service';
import Base from './components/Layout/Base';
import { useAuth } from './contexts/auth.context';
import PageLoader from './components/Common/PageLoader';
import { hasAccess } from './components/AccessControl';

interface AuthenticatedRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
  action?: string
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ component: Component, action, ...rest }) => {

  const { currentUser, isLoading } = useAuth();

  if (!AuthService.isAuthenticated()) {
    return <Redirect to='/login' />
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (currentUser === null) {
    return <Redirect to='/login' />
  }

  return (
    <Route exact
      {...rest}
      render={props =>
       action && !hasAccess(currentUser.role, action)  ? (
         <Redirect to='/users' />
        ) : (
          <Base currentUser={currentUser}>
            <Component {...props} />
          </Base>
        )
      }
    />
  );
}

export default AuthenticatedRoute;
