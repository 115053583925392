const scheduledExportQueries: object = {
  GET_SCHEDULED_EXPORT_DETAILS: (params: object) => {
    const query = `query scheduledExport($id: Int!) {
      scheduledExport(id:$id){
        id
        name
        timeOfDay
        store {
          storeCode
          storeName
        }
        merchant {
          id
          name
        }
        statusId
        exportLocationId
        startDate
        endDate
      }
    }`
    return {
      operationName: null,
      query,
      variables: params
    }
  },

  GET_SCHEDULED_EXPORTS: () => {
    const query = `query scheduledExports {
      scheduledExports{
        id
        name
        timeOfDay
        store {
          storeCode
          storeName
        }
        merchant {
          id
          name
        }
        statusId
        exportLocationId
        startDate
        endDate
      }
    }`
    return {
      operationName: null,
      query,

    }
  },

}

export default scheduledExportQueries as {
  [key: string]: Function;
}
