import { Dispatch } from 'redux';
import { IReduxAction } from '../../types';
import logger from '../../libs/utils/logger';
import { IOrderItemRecord } from 'types/order.types';
import { getOrders, getOrdersByMerchantId } from 'services/order.service';
import {PAGINATION_TABLE_LIMIT} from "../../constants";

export const GET_ORDERS_STARTED = 'GET_ORDERS_STARTED';
export const GET_ORDERS_FINISHED = 'GET_ORDERS_FINISHED';
export const GET_ORDERS_BY_MERCHANT_ID_STARTED = 'GET_ORDERS_BY_MERCHANT_ID_STARTED';
export const GET_ORDERS_BY_MERCHANT_ID_FINISHED = 'GET_ORDERS_BY_MERCHANT_ID_FINISHED';

export interface OrdersState {
  isFetching: boolean;
  orders: IOrderItemRecord[]
}

interface GetOrdersAction {
  type: typeof GET_ORDERS_STARTED | typeof GET_ORDERS_FINISHED | typeof GET_ORDERS_BY_MERCHANT_ID_STARTED | typeof GET_ORDERS_BY_MERCHANT_ID_FINISHED,
  payload: Array<Object> | Object // payload
}

export type OrdersAction = GetOrdersAction;

export const getOrdersStarted = () => ({
  type: GET_ORDERS_STARTED,
});

export const getOrdersFinished = (data: OrdersState): IReduxAction => ({
  type: GET_ORDERS_FINISHED,
  payload: data,
});

export const getOrdersAction = () => {
  // The typings for redux + redux-thunk are weak and incorrect in some cases
  return (dispatch: Dispatch<any>) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    dispatch(getOrdersStarted());

    return getOrders({pageNumber:1, pageSize:PAGINATION_TABLE_LIMIT}).then(ordersData => {
      dispatch(getOrdersFinished(ordersData.data));
    }).catch((err) => {
      logger.error(err);
    })
  };
}

export const getOrdersByMerchantIdStarted = () => ({
  type: GET_ORDERS_BY_MERCHANT_ID_STARTED,
});

export const getOrdersByMerchantIdFinished = (data: OrdersState): IReduxAction => ({
  type: GET_ORDERS_BY_MERCHANT_ID_FINISHED,
  payload: data,
});

export const getOrdersByMerchantIdAction = (id: number) => {
  // The typings for redux + redux-thunk are weak and incorrect in some cases
  return (dispatch: Dispatch<any>) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    dispatch(getOrdersByMerchantIdStarted());

    return getOrdersByMerchantId({id}).then(data => {
      dispatch(getOrdersByMerchantIdFinished(data));
    }).catch((err) => {
      logger.error(err);
    })
  };
}
